import { Controller } from '@hotwired/stimulus';
import tippy from "tippy.js";

export default class extends Controller {
    connect() {
        // this.element.textContent = 'Hello Stimulus! Edit me in theme_src/controllers/foobar_controller.js';
        // console.log(this.isProcessed());

        if (!this.isProcessed()) {
            this.addCopyright();
        }
    }

    addCopyright() {
        const elem = this.element;

        const copyrightText = elem.getAttribute('data-copyright');

        if (!copyrightText) {
            return;
        }

        // create wrapper
        var wrapper = document.createElement('div');
        wrapper.classList.add('c-image-container');

        // wrapper element
        elem.parentNode.insertBefore(wrapper, elem);
        wrapper.appendChild(elem);

        // add link
        const copyrightLinkHtml = '<a role="button" tabindex="0" class="js-copyright c-image-container__copyright-link" data-tippy-content="' + copyrightText + '" title="Copyright Informationen anzeigen">Copyright</a>';
        elem.insertAdjacentHTML('afterend', copyrightLinkHtml);

        elem.setAttribute('data-cradded', '1');

        tippy('.js-copyright', {
            theme: "standard",
            trigger: "mouseenter focus",
            placement: "top-end",
            arrow: false,
            interactive: true,
            aria: {
                // `null` when interactive: true, otherwise "describedby"
                content: 'describedby',
                // matches `interactive` boolean
                expanded: 'auto',
            },
        });

    }

    isProcessed() {
        return (this.element.hasAttribute('data-cradded'));
    }
}
