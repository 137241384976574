

import { Controller } from '@hotwired/stimulus';

const $ = require('jquery');

export default class extends Controller {
    connect() {
        this.hash = window.location.hash.replace('#', '');

        this.prepareTabGroup(this.uid(), this.element);
        // this.initTabGroup(this.element);
    }

    uid() {
        return '_' + Math.random().toString(36).substr(2, 9);
    };

    prepareTabGroup(groupIndex, tabGroup) {
        let _self = this;

        let isFullWidth = $(tabGroup).hasClass('ce_tabcontrol--full-width');
        let isInitiallyClosed = $(tabGroup).hasClass('ce_dztabs--closed');
        let isHash = $(tabGroup).hasClass('ce_dztabs--hash');

        if (!isInitiallyClosed) {
            $(tabGroup).addClass('active');
        }

        // Find active index
        let activeIndex = 0;
        if (isHash) {
            activeIndex = -1;
        }

        // console.log(tabGroup);
        // console.log(activeIndex);
        // console.log('isInitiallyClosed', isInitiallyClosed);


        // open tab if url hash correspond with custom style class name (set in contao backend)
        // http(s)://.../page.html#specialtab <-> class="... specialtab ..."
        // $('.ce_dztabs__pane', tabGroup).each(function (paneIndex, pane) {
        //     let cssClass = $(pane).attr('class');
        //     if (cssClass.indexOf(' ' + _self.hash + ' ') !== -1) {
        //         activeIndex = paneIndex;
        //     }
        // });

        // Set attributes for tab navigation
        $('.nav-link', tabGroup).each(function (tabIndex, tab) {
            $(tab).attr('id', 'tab_' + groupIndex + '_' + tabIndex);
            //$(tab).attr('href', '#tab_pane_' + groupIndex + '_' + tabIndex);
            $(tab).attr('aria-controls', 'tab_pane_' + groupIndex + '_' + tabIndex);
            $(tab).attr('data-bs-target', '#tab_pane_' + groupIndex + '_' + tabIndex);

            $(tab).attr('tabindex', '-1');

            if (!isInitiallyClosed && (tabIndex === activeIndex)) {
                $(tab).addClass('active');
                $(tab).attr('tabindex', '0');
                $(tab).attr('aria-selected', 'true');
            }
        });

        // Set attributes for tab panes
        $('.tab-pane', tabGroup).each(function (paneIndex, pane) {
            var relatedTabLabel = $('#tab_' + groupIndex + '_' + paneIndex + ' .label').html();

            $(pane)
                .attr('id', 'tab_pane_' + groupIndex + '_' + paneIndex)
                .attr('aria-labelledby', 'tab_' + groupIndex + '_' + paneIndex);

                // .addClass('is-hidden');

            // for print only
            // $(pane).prepend('<h3 aria-hidden="true" class="only-print">' + relatedTabLabel + '</h3>')

            if (!isInitiallyClosed && (paneIndex === activeIndex)) {
                $(pane).addClass('active');
                // .removeClass('is-hidden');
            }
        });

    }

}



