const $ = require('jquery');

var survey = function () {

    $('.js-survey-cb').on('change', function (event) {
        console.log('click');
        if ($('#18').is(":checked") || $('#u18').is(":checked")) {
            console.log('remove');
            $('.js-survey-submit').removeAttr('disabled');
        } else {
            $('.js-survey-submit').attr('disabled', 'disabled');
        }
    });
}

export default survey;