import './bootstrap.js';

import Parallax from 'parallax-js';

var scene = document.getElementById('ensastage');
if (scene) {
    var parallaxInstance = new Parallax(scene);
}

import tippy from 'tippy.js';

tippy('.js-copyright', {
    theme: "standard",
    trigger: "mouseenter focus",
    placement: "top-end",
    arrow: false,
    interactive: true,
    aria: {
        // `null` when interactive: true, otherwise "describedby"
        content: 'describedby',
        // matches `interactive` boolean
        expanded: 'auto',
    },
});

const $ = require('jquery');

import ScrollCounter from "./js/scrollCounter";
ScrollCounter();

import StickyHeader from "./js/stickyHeader";
StickyHeader();

import EventSearchForm from "./js/eventSearchForm";
EventSearchForm();

import Survey from "./js/survey";
Survey();

import VideoModal from "./js/videoModal";
VideoModal();

import ProductFinder from "./js/productFinder";
ProductFinder('product-finder');
ProductFinder('product-finder-acc', {isAccordion: true});


import Swiper from 'swiper';
import { Navigation, Pagination, Thumbs, Mousewheel, EffectFade } from 'swiper/modules';

// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';


const gallerySwipers = document.querySelectorAll('.content-gallery.standard');

for(var i=0; i< gallerySwipers.length; i++ ) {

    gallerySwipers[i].classList.add('js-gallery-swiper-' + i);

// init Swiper:
    var thumbSwiper = new Swiper('.js-gallery-swiper-' + i + ' .thumb-swiper', {
        modules: [Navigation],

        loop: true,
        centeredSlides: true,
        spaceBetween: 8,
        slidesPerView: 2,
        freeMode: true,
        watchSlidesProgress: true,

        breakpoints: {
            320: {
                slidesPerView: 2,
            },
            576: {
                slidesPerView: 3,
            },
            768: {
                // slidesPerView: 4,
            },
            992: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 4,
            },
            1600: {
                slidesPerView: 5,
            }
        },

        // xs: 320px,
        // sm: 576px,
        // md: 768px,
        // lg: 992px,
        // xl: 1200px,
        // xxl: 1600px

        navigation: {
            nextEl: '.js-gallery-swiper-' + i + ' .thumb-swiper .swiper-button-next',
            prevEl: '.js-gallery-swiper-' + i + ' .thumb-swiper .swiper-button-prev',
        },
    });

    var swiper = new Swiper('.js-gallery-swiper-' + i + ' .gallery-swiper', {
        // configure Swiper to use modules
        modules: [Navigation, Pagination, Thumbs],
        // ...
        // pagination: {
        //     el: '.swiper-pagination',
        // },

        // Navigation arrows
        // navigation: {
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev',
        // },
        thumbs: {
            swiper: thumbSwiper,
        },
    });

}

const bgSwipers = document.querySelectorAll('.js-bg-swiper');

for(var i=0; i< bgSwipers.length; i++ ) {

    bgSwipers[i].classList.add('js-bg-swiper-' + i);

    const bgSliderSwiper = new Swiper('.js-bg-swiper-' + i + ' .bg-slider-swiper', {
        // configure Swiper to use modules
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        // ...
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.js-bg-swiper-' + i + ' .swiper-button-next',
            prevEl: '.js-bg-swiper-' + i + ' .swiper-button-prev',
        },

        on: {
            slideChange: function(swiper) {
                // console.log(swiper.activeIndex);
            }
        }
    });

}

// const teaserSliders = document.querySelectorAll('.teaser-swiper');
const projectTeasers = document.querySelectorAll('.js-teaser-swiper');

for(var i=0; i< projectTeasers.length; i++ ) {

    projectTeasers[i].classList.add('js-teaser-swiper-' + i);

    var maxSlides = 3;

    if (projectTeasers[i].parentNode.classList.contains('slides-4')) {
        maxSlides = 4;
    }

    new Swiper('.js-teaser-swiper-' + i + ' .teaser-swiper', {
        // configure Swiper to use modules
        modules: [Navigation, Pagination],

        slidesPerView: 3,
        // ...
        spaceBetween: 20,

        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: maxSlides,
            },
            1600: {
                slidesPerView: maxSlides,
            }
        },

        // Navigation arrows
        navigation: {
            nextEl: '.js-teaser-swiper-' + i + ' .swiper-button-next',
            prevEl: '.js-teaser-swiper-' + i + ' .swiper-button-prev',
        },

    });

}

// new Swiper('.teaser-swiper', {
//     // configure Swiper to use modules
//     modules: [Navigation, Pagination],
//
//     slidesPerView: 3,
//     // ...
//     spaceBetween: 20,
//
//     breakpoints: {
//         320: {
//             slidesPerView: 1,
//         },
//         576: {
//             slidesPerView: 1,
//         },
//         768: {
//             slidesPerView: 2,
//         },
//         992: {
//             slidesPerView: 3,
//         },
//         1200: {
//             slidesPerView: 3,
//         },
//         1600: {
//             slidesPerView: 3,
//         }
//     },
//
//     // Navigation arrows
//     navigation: {
//         nextEl: '.swiper-button-next',
//         prevEl: '.swiper-button-prev',
//     },
// });

new Swiper('.vertical-gallery-swiper', {
    // configure Swiper to use modules
    modules: [Navigation, Pagination, Thumbs, Mousewheel],

    slidesPerView: 1,
    autoHeight: true,
    direction: "vertical",
    mousewheel: true,
    // height: 300,

    on: {
        afterInit: (swiper) => {
            // swiper.height = 500;
            // let totalGap = swiper.passedParams.spaceBetween * (swiper.passedParams.slidesPerView - 1);
            // let containerHeight = swiper.passedParams.slidesPerView * swiper.slides[0].clientHeight + totalGap;
            // swiper.el.style.height = containerHeight + 'px';
            const slides = document.querySelectorAll('.vertical-gallery-swiper .swiper-slide');
            // console.log(slides.length);

            slides.forEach(function(slide) {
                // slide.style.height = '500px'
                // console.log(slide.style.height);
            });
        },
    },

    // ...
    // pagination: {
    //     el: '.swiper-pagination',
    // },

    // Navigation arrows
    // navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    // },
    thumbs: {
        swiper: thumbSwiper,
    },
});

new Swiper('.profile-homepage .video-swiper', {
    // configure Swiper to use modules
    modules: [Navigation],

    slidesPerView: "auto",
    centeredSlides: true,
    // initialSlide: 1,
    loop: true,
    // ...
    spaceBetween: 20,

    breakpoints: {
    //     320: {
    //         slidesPerView: 1,
    //     },
    //     576: {
    //         slidesPerView: 2,
    //     },
    //     768: {
    //         // slidesPerView: 4,
    //     },
        992: {
            slidesPerView: 3,

            // loop: false,
            centeredSlides: false,
        },
    //     1200: {
    //         slidesPerView: 3,
    //     },
    //     1600: {
    //         slidesPerView: 3,
    //     }
    },

    // Navigation arrows
    navigation: {
        nextEl: '.profile-homepage .swiper-button-next',
        prevEl: '.profile-homepage .swiper-button-prev',
    },
});

const timelineSwipers = document.querySelectorAll('.content-timeline');

for(var i=0; i< timelineSwipers.length; i++ ) {

    timelineSwipers[i].classList.add('js-timeline-swiper-' + i);

    // addTimeline();

    let swiperNum = i;

    const timelineSwiper = new Swiper('.js-timeline-swiper-' + swiperNum + ' .timeline-swiper', {
        // configure Swiper to use modules
        modules: [Navigation, Pagination, EffectFade],
        slidesPerView: 1,
        speed: 1500,
        // effect: 'fade',
        // fadeEffect: {
        //     crossFade: true
        // },
        // ...
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.js-timeline-swiper-' + swiperNum + ' .swiper-button-next',
            prevEl: '.js-timeline-swiper-' + swiperNum + ' .swiper-button-prev',
        },

        on: {
            afterInit: function(swiper) {
                initTimeline(swiper, swiperNum);
            },
            slideChange: function(swiper) {
                console.log('slide change');
                // console.log(swiper.activeIndex);

                centerDot(swiper.activeIndex, swiperNum);
            },
            transitionStart: function(swiper) {
                document.querySelector('.js-timeline-swiper-' + swiperNum).classList.add('swiping');
            },
            transitionEnd: function(swiper) {
                document.querySelector('.js-timeline-swiper-' + swiperNum).classList.remove('swiping');
            }
        }
    });

}

function initTimeline(swiper, i)
{
    console.log(i);

    let dots = document.querySelectorAll('.js-timeline-swiper-' + i + ' .timeline-dot');

    Array.from(dots).forEach(function (element) {
        element.addEventListener('click', function(event) {
            // centerDot(event, swiper, i);
            changeSlide(event, swiper, i);
        }, false);
    });
}

function changeSlide(event, swiper) {
    const button = event.currentTarget;
    const pos = button.getAttribute('data-timeline-pos');

    swiper.slideTo(pos);
}

// function centerDot(event, swiper, i)
// {
//     const button = event.currentTarget;
//     const pos = button.getAttribute('data-timeline-pos');
//     let buttonWidth = button.offsetWidth;
//
//
//     console.log(i);
//     swiper.slideTo(pos);
//
//     let posLeft = parseInt(pos) * buttonWidth;
//     let sliderWidth = document.querySelector('.js-timeline-swiper-' + i).offsetWidth;
//
//     let newPosPane = (-1 * posLeft) + (sliderWidth/2 - buttonWidth/2);
//
//     if (newPosPane > 0) {
//         newPosPane = 0;
//     }
//
//     console.log(posLeft);
//     console.log(sliderWidth);
//     console.log(newPosPane);
//
//     document.querySelector('.js-timeline-swiper-' + i + ' .timeline-nav-pane').style.left = newPosPane + 'px';
// }

function centerDot(pos, i)
{
    let buttons = document.querySelectorAll('.js-timeline-swiper-' + i + ' .timeline-dot');
    buttons.forEach(function(button, index) {
       button.classList.remove('active');
    });

    let activeButton = buttons[pos];
    activeButton.classList.add('active');

    let buttonWidth = document.querySelector('.js-timeline-swiper-' + i + ' .timeline-dot').offsetWidth;
    let paddingWidth  = document.querySelector('.js-timeline-swiper-' + i + ' .timeline-padding').offsetWidth;
    let paneWidth  = document.querySelector('.js-timeline-swiper-' + i + ' .timeline-nav-pane').offsetWidth;


    console.log(i);
    // swiper.slideTo(pos);

    let posLeft = parseInt(pos) * buttonWidth + paddingWidth;
    let sliderWidth = document.querySelector('.js-timeline-swiper-' + i).offsetWidth;

    let newPosPane = (-1 * posLeft) + (sliderWidth/2 - buttonWidth/2);

    if (newPosPane > 0) {
        newPosPane = 0;
    }

    if (newPosPane < (sliderWidth - paneWidth)) {
        newPosPane = sliderWidth - paneWidth;
    }

    console.log(posLeft);
    console.log(sliderWidth);
    console.log(newPosPane);

    document.querySelector('.js-timeline-swiper-' + i + ' .timeline-nav-pane').style.left = newPosPane + 'px';
}


// function initTimeline() {
//     var startIndex = $('.js-timeline-layers > div.active').index();
//
//     var $wrapper = $('.js-timeline-layers');
//
//     console.log('init timeline');
//     // $('.js-timeline-layers').each(function() {
//     //var el = $(this).children().prop("tagName").toLowerCase();
//     //alert(el);
//     //console.log('slider rtl');
//     $wrapper.slick({
//         initialSlide: startIndex,
//         slide: 'div',
//         dots: false,
//         adaptiveHeight: true,
//         rtl: false
//     });
//
//     $wrapper.on('beforeChange', function(event, slick, currentSlide, nextSlide){
//         var $currentSlide = $(slick.$slides.get(nextSlide));
//         var year = $currentSlide.data('year');
//
//         $('.ce_dz_timeline__dot').removeClass('active');
//         $('#dot-' + year).addClass('active');
//         centerDot(year);
//     });
//
//     // });
//
//     $('.js-show-year').on('click', function() {
//         var actIndex = $(this).index();
//         var actYear = $(this).data('year');
//
//         console.log(actYear);
//         var gotoIndex = $('#tl-' + actYear).data('slick-index');
//         console.log(gotoIndex);
//         $wrapper.slick('slickGoTo', gotoIndex);
//
//         centerDot(actYear);
//     });
//
//     var dotSlider = $('.ce_dz_timeline__mask');
//     var isDown = false;
//     var startX;
//     var scrollLeft;
//
//     dotSlider.on('mousedown', function(e) {
//         isDown = true;
//         dotSlider.addClass('active');
//         startX = e.pageX - dotSlider.offset().left;
//         scrollLeft = dotSlider.scrollLeft();
//     });
//     dotSlider.on('mouseleave', function(e) {
//         isDown = false;
//         dotSlider.removeClass('active');
//     });
//     dotSlider.on('mouseup', function() {
//         isDown = false;
//         dotSlider.removeClass('active');
//     });
//     dotSlider.on('mousemove', function(e) {
//         if(!isDown) return;
//         e.preventDefault();
//         var x = e.pageX - dotSlider.offset().left;
//         var walk = (x - startX) * 3; //scroll-fast
//         dotSlider.scrollLeft(scrollLeft - walk);
//         console.log(walk);
//     });
// }
//
// function centerDot(year) {
//     var maskWidth = $('.ce_dz_timeline__mask').width();
//     var posAbsDot = parseInt($('#dot-' + year).css('left'), 10);
//     var scrollLeft = Math.floor(posAbsDot - (maskWidth/2));
//     console.log(scrollLeft);
//     // $('.ce_dz_timeline__mask').scrollLeft(0);
//
//     $('.ce_dz_timeline__mask').animate({scrollLeft: scrollLeft}, 800);
// }



/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import * as bootstrap from 'bootstrap'

// console.log('app.js');

// any CSS you import will output into a single css file (app.css in this case)
import './app.scss';
