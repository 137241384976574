import { Controller } from '@hotwired/stimulus';


export default class extends Controller {
    static values = {
        field: Number,
        tg: String,
        offset: Number
    }

    connect() {
        // console.log('product controller');
    }

    async more(event) {
        event.stopPropagation();

        const actElement = this.element;
        const container = this.element;

        const params = new URLSearchParams({
            field: this.fieldValue,
            tg: this.tgValue,
            offset: this.offsetValue
        });

        const url = '/products/load'

        const response = await fetch(`${url}?${params.toString()}`);

        var jsonData = await response.json();

        // var productNode = new Node();
        // productNode.innerHTML = jsonData.products;

        // var productNode = document.createElement('template');
        // productNode.innerHTML = jsonData.products;

        // console.log(jsonData.products);

        // const productNode = this.fromHTML(jsonData.products);

        // this.element.parentNode.append(productNode);

        // actElement.style.display = 'none';

        container.innerHTML += jsonData.products;

        this.hideButton(container);
        this.showItems(container);

        // console.log(jsonData.products);
    }

    fromHTML(html, trim = true) {
        // Process the HTML string.
        html = trim ? html.trim() : html;
        if (!html) return null;

        // Then set up a new template element.
        const template = document.createElement('template');
        template.innerHTML = html;
        const result = template.content.children;

        // console.log(result);

        // Then return either an HTMLElement or HTMLCollection,
        // based on whether the input HTML had one or more roots.
        if (result.length === 1) return result[0];
        return result[0];
    }

    showItems(parentNode) {
        // console.log(parentNode);

        const childrens = parentNode.querySelectorAll('.is-product');

        childrens.forEach(function(e) {
            e.classList.remove('hide-on-mobile-sm');
        });
    }

    hideButton(parentNode) {
        // console.log(parentNode);

        const childrens = parentNode.querySelectorAll('.hide-after-load');

        childrens.forEach(function(e) {
            e.style.display = 'none';
        });
    }
}
