var productFinder = function (elementId) {
    const isAccordion = false;

    let defaults = {
        isAccordion: false
    };

    const settings = (arguments[1] && typeof arguments[1] === 'object') ? extendDefaults(defaults,arguments[1]) : defaults;

    // console.log(elementId);
// console.log(settings);

    const offset = 500;
    const interval = 300;

    var container;
    var isOpen = false;


    document.addEventListener("DOMContentLoaded", function () {
        container = document.getElementById(elementId);

        container.addEventListener('tileClosed', handleTileClosed);
        container.addEventListener('tileChanged', handleTileChanged);

        document.querySelectorAll('.js-product-close-all').forEach(function (item) {
            item.addEventListener('click', closeAllCategories);
        });

        document.querySelectorAll('.js-product-show-all').forEach(function (item) {
            item.addEventListener('click', openAllCategories);
        })

        const toggleElements = document.querySelectorAll(".js-product-toggle-category")

        toggleElements.forEach(function (item) {
            item.addEventListener('click', handleToggleCategory);
        });

        const closeElements = document.querySelectorAll(".js-product-close-category")

        closeElements.forEach(function (item) {
            item.addEventListener('click', handleCloseCategory);
        })
    });

    var handleToggleCategory = function (event) {
        const categoryButton = event.currentTarget;

        if (settings.isAccordion && hasOpenCategories()) {
            closeAllCategories(categoryButton);
        }

        categoryButton.classList.toggle('open');

        isOpen = container.querySelectorAll('.product-tile-category.open').length > 0;

        if (isOpen) {
            container.classList.add('active');
        }

        var color = categoryButton.getAttribute('data-tile');

        var childs = categoryButton.parentNode.querySelectorAll('.product-tile-content-' + color);

        toggleChilds(categoryButton, childs);
    }

    const handleCloseCategory = function (event) {
        const color = event.currentTarget.getAttribute('data-tile');

        const category = container.querySelector('.product-tile-' + color);

        category.click();
    }

    const handleTileClosed = function (event) {
        if (container.querySelectorAll('.product-tile-content.open').length === 0) {
            // all tiles closed
            container.classList.remove('active');
        }
    }

    const handleTileChanged = function (event) {
        if (container.querySelectorAll('.product-tile-content.open').length === 0) {
            // all tiles closed
            container.classList.remove('active');
        } else {
            if (!container.classList.contains('active')) {
                container.classList.add('active');
            }
        }
    }

    const hasOpenCategories = function () {
        return container.querySelectorAll('.product-tile-category.open').length > 0;
    }

    const closeAllCategories = function (actCategory) {
        const openCategories = container.querySelectorAll('.product-tile-category.open');

        openCategories.forEach(function (category) {
            if (category !== actCategory) {
                category.click();
            }
        });
    }

    const openAllCategories = function () {
        const openCategories = container.querySelectorAll('.product-tile-category');

        openCategories.forEach(function (category) {
            if (!category.classList.contains('open')) {
                category.click();
            }
        });
    }

    function toggleChilds(category, childs) {
        const numChilds = childs.length;

        const reverse = !category.classList.contains('open');
        // console.log(reverse);

        for (let i = 0; i < numChilds; i++) {
            // console.log(i);
            let item = childs[i];
            let startTime = i * interval + offset;

            if (reverse) {
                item = childs[numChilds - i - 1];
                startTime = i * 0;
            }

            toggleTile(item, startTime);
        }
    }

    async function toggleTile(item, time) {
        await sleep(time);
        item.classList.toggle('open');

        if (!item.classList.contains('open')) {
            // container.dispatchEvent(new CustomEvent('tileClosed'));
        }

        container.dispatchEvent(new CustomEvent('tileChanged'));
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function extendDefaults(defaults,properties)
    {
        Object.keys(properties).forEach(property => {
            if(properties.hasOwnProperty(property))
            {
                defaults[property] = properties[property];
            }
        });
        return defaults;
    }
}

export default productFinder;