const $ = require('jquery');

var stickyHeader = function () {
    // Hide Header on on scroll down

    var target = '.header-sticky-container';

    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $(target).outerHeight();

    $(window).scroll(function (event) {
        didScroll = true;
    });

    setInterval(function () {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(window).scrollTop();

        // console.log(st);
        if (st > 0) {
            $(target).addClass('nav-scrolled');
        } else {
            $(target).removeClass('nav-scrolled');
        }

// Make sure they scroll more than delta
        if (Math.abs(lastScrollTop - st) <= delta)
            return;

        // console.log(st + ' ' + lastScrollTop);
// If they scrolled down and are past the navbar, add class .nav-up.
// This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight) {
// Scroll Down
            $(target).removeClass('nav-down').addClass('nav-up');
        } else {
// Scroll Up
            if (st + $(window).height() < $(document).height()) {
                $(target).removeClass('nav-up').addClass('nav-down');
            }
        }

        lastScrollTop = st;
    }

}

export default stickyHeader;