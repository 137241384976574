import { Controller } from '@hotwired/stimulus';


export default class extends Controller {
    static targets = [ "message" ]

    connect() {
        console.log('stimulustest:connect')
        // this.element.textContent = 'theme_src/controllers/stimulus-test_controller.js';
    }

    change(event) {
        // console.log(this.messageTarget.innerHTML);
        this.messageTarget.innerHTML = 'bar';

    }
}
