import { Controller } from '@hotwired/stimulus';


export default class extends Controller {
    static values = {
        // field: Number,
        // tg: String,
        // offset: Number
    }

    connect() {
        // console.log('profile controller');

        // var tag = document.createElement('script');
        //
        // tag.src = "https://www.youtube.com/iframe_api";
        // var firstScriptTag = document.getElementsByTagName('script')[0];
        // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        //
        // var player = [];
        //
        // function onYouTubeIframeAPIReady() {
        // }
        //
        // var profileModals = document.getElementsByClassName('js-profile-modal');
        //
        // for (var i = 0; i < profileModals.length; i++) {
        //     profileModals[i].addEventListener('shown.bs.modal', function (event) {
        //         let playerId = event.currentTarget.getAttribute('data-player-id');
        //         let embed = event.currentTarget.getAttribute('data-profile-embed');
        //
        //         // console.log(player[playerId]);
        //
        //         if (player[playerId]) {
        //             // console.log('player exists');
        //
        //             player[playerId].playVideo();
        //
        //             player[playerId].loadVideoById({
        //                 'videoId': embed,
        //                 'startSeconds': 30,
        //                 'endSeconds': 60
        //             });
        //
        //         } else {
        //             // console.log('create new player');
        //
        //             player[playerId] = new YT.Player('player' + playerId, {
        //                 height: '400',
        //                 width: '300',
        //                 videoId: embed,
        //                 playerVars: {
        //                     'playsinline': 1,
        //                     'start': 30,
        //                     'end': 60,
        //                     // 'origin': 'https://eg-website.local'
        //                 },
        //                 events: {
        //                     'onReady': onPlayerReady,
        //                     // 'onStateChange': onPlayerStateChange
        //                 }
        //             });
        //         }
        //     });
        //
        //     profileModals[i].addEventListener('hide.bs.modal', function (event) {
        //         let playerId = event.currentTarget.getAttribute('data-player-id');
        //
        //         // console.log('stop');
        //         player[playerId].stopVideo();
        //     });
        // }
        //
        // function onPlayerReady(event) {
        //     // console.log('play');
        //     // console.log(event.target);
        //     event.target.playVideo();
        //     event.target.mute();
        // }

    }

}
