var videoModal = function() {
    var videoModals = document.getElementsByClassName('js-video-modal');

    for (var i = 0; i < videoModals.length; i++) {
        videoModals[i].addEventListener('hide.bs.modal', function (event) {
            let playerId = 'player' + event.currentTarget.getAttribute('data-player-id');

            // console.log(playerId);

            document.getElementById(playerId).contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');

            // console.log('stop');
            // player[playerId].stopVideo();
        });
    }

    var profileModals = document.getElementsByClassName('js-profile-modal');

    for (var i = 0; i < profileModals.length; i++) {
        profileModals[i].addEventListener('shown.bs.modal', function (event) {
            let playerId = 'player' + event.currentTarget.getAttribute('data-player-id');

            // console.log('start:'  + playerId);

            /* disable auto play in iframe */
            document.getElementById(playerId).contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');

        });
    }
}

export default videoModal;