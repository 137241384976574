const $ = require('jquery');

var eventSearchForm = function () {

    var $element = $('.mod_dzeventsearchform');

    $element.find('.change-filter').on('click', function (event) {
        $(this).parent().find('.cbm-container').slideToggle();
        $(this).find('span').toggleClass('active');
        $(this).toggleClass('open');
        $(this).parent().toggleClass('open');

    });

    $element.find('.cbm-item').on('change', function (event) {
        var selectedValues = [];
        $(this).parents('.cbm-container').find('.cbm-item:checked').each(function () {
            //if ($(this).attr('checked') == 'checked') {
            selectedValues.push($(this).parent().find('label').html());
            //}

        });
        if (!selectedValues.length) {
            $(this).parents('.filter-element').find('.cbm-selected-values').html('Alle');
        } else {
            $(this).parents('.filter-element').find('.cbm-selected-values').html(selectedValues.join(', '));
        }

    });

}

export default eventSearchForm;