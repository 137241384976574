import {Controller} from '@hotwired/stimulus';


export default class extends Controller {
    static values = {
        // field: Number,
        // tg: String,
        embed: String
    }

    connect() {
        console.log('profile controller');
        // console.log(this.embedValue);

        var tag = document.createElement('script');

        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = this.loadVideo.bind(this);

        var player;



        // function onYouTubeIframeAPIReady() {
        //     console('yt ready');

        // }


        function onPlayerReady(event) {
            // console.log('play');
            // console.log(event.target);
            // event.target.playVideo();
        }

    }

    play(event) {
        document.getElementById('profile-cover').style.display = 'none';
        this.player.seekTo(0);
        this.player.playVideo();
    }

    chapter({ params: { embed } }) {
        if (document.getElementById(embed).classList.contains('playing')) {
            this.player.pauseVideo();
            document.getElementById(embed).classList.remove('playing');
            document.getElementById(embed).classList.add('paused');

        } else if (document.getElementById(embed).classList.contains('paused')) {
            this.player.playVideo();
            document.getElementById(embed).classList.add('playing');
            document.getElementById(embed).classList.remove('paused');

        } else {
            this.player.loadVideoById(embed, 0, 'large');

            document.getElementById('profile-cover').style.display = 'none';

            this.resetPaused();
            this.resetPlaying();
            this.resetSelected();

            document.getElementById(embed).classList.add('selected');

            this.player.seekTo(0);
            this.player.playVideo();
        }
    }

    resetSelected() {
        [...document.getElementsByClassName('js-profile-chapter')].forEach(function(el){
            el.classList.remove('selected');
        });
    }

    resetPlaying() {
        [...document.getElementsByClassName('js-profile-chapter')].forEach(function(el){
            el.classList.remove('playing');
        });
    }
    resetPaused() {
        [...document.getElementsByClassName('js-profile-chapter')].forEach(function(el){
            el.classList.remove('paused');
        });
    }

    activateSelected() {
        [...document.getElementsByClassName('js-profile-chapter selected')].forEach(function(el){
            el.classList.add('playing');
        });
    }

    pausePlaying() {
        [...document.getElementsByClassName('js-profile-chapter playing')].forEach(function(el){
            el.classList.add('paused');
        });
    }

    pause(event) {
        this.player.pauseVideo();
    }

    loadVideo() {
        // console.log('yt loaded 2');
        // console.log(this.embedValue);

        var _self = this;

        this.player = new YT.Player('profilePlayer', {
            // height: '400',
            // width: '300',
            // videoId: this.embedValue,
            playerVars: {
                'playsinline': 1,
                // 'start': 30,
                // 'end': 60,
                // 'origin': 'https://eg-website.local'
            },
            events: {
                // 'onReady': onPlayerReady,
                'onStateChange': _self.onPlayerStateChange.bind(_self)
            }
        });
    }

    onPlayerStateChange(event) {
        if (event.data == YT.PlayerState.PAUSED) {
            this.pausePlaying();
            this.resetPlaying();
        }
        if (event.data == YT.PlayerState.ENDED) {
            this.resetPlaying();
        }
        if (event.data == YT.PlayerState.PLAYING) {
            this.activateSelected();
        }
    }

}
